<template>
  <div
    :class="{appLoggedOff : isLoggedOff}"
    class="appSectionContainer"
    @keydown.esc="closeForms"
  >
    <input style="display:none">
    <input
      type="password"
      style="display:none"
    >
    <CreateUser
      @userCreated="() => { handleUserCreated() }"
    />
    <Spinner v-show="loading" />
    <div
      v-show="!loading"
    >
      <div v-if="viewEntityPermission">
        <div
          v-if="createEntityPermission || internalUser"
          class="appSectionHeader"
        >
          <div class="divInternalUsers">
            <label
              v-if="internalUser"
              for="chkInternalUsers"
              style="vertical-align:text-bottom;margin-right:8px;"
            >
              Hide Internal Users
            </label>
            <TriStateToggleSwitch
              v-if="internalUser"
              id="chkInternalUsers"
              :enabled="true"
              :tri-state="false"
              :current-state="showInternal"
              class="toggleSwitch"
              tabindex="1"
              @statechanged="toggleUsers"
            />
          </div>
          <TextButton
            v-if="createEntityPermission"
            :button-tabindex="10"
            button-style="right: 5vw; top: 5px;width:160px;"
            button-id="btnNewUser"
            title="Add a new user"
            text="Add New User"
            @click="openNewUser"
          />
        </div>
        <Spinner v-show="loading" />
        <div class="mdContainer appSectionBody">
          <div
            :class="{mdHalfScreen : !!selectedUser}"
            class="mdUserManagement"
          >
            <table id="usersTable">
              <thead />
              <tbody>
                <tr
                  v-for="v in currentFilter"
                  :key="v.id"
                  :class="getRowColour(v.id)"
                  :tabindex="20"
                  @click="selectUsername(v)"
                  @keyup="testSelectUser($event, v)"
                >
                  <td>{{ v.userName }}</td>
                  <td>{{ v.firstName }}</td>
                  <td>{{ v.lastName }}</td>
                  <td>{{ v.emailAddress }}</td>
                  <td>{{ v.locked }}</td>
                  <td>{{ v.disabled }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            v-if="!!selectedUser"
            class="mdSelection"
          >
            <SectionCollapsedHeader
              id="sectionUpdateUser"
              :title="updateUserComponent"
              type="subHeader"
              icon-file="Edit"
              controls="updateUserComponent"
            />
            <UpdateUser
              id="updateUserComponent"
              :user="selectedUser"
            />
            <SectionCollapsedHeader
              v-if="createEntityPermission"
              id="sectionChangeUserEmail"
              title="Change User Email Address"
              type="subHeader"
              icon-file="email"
              controls="changeUserEmail"
            />
            <ChangeUserEmail
              v-if="createEntityPermission"
              :user="selectedUser"
            />
            <SectionCollapsedHeader
              v-if="updateEntityPermission && selectedUser.id !== currentUserId"
              id="sectionPasswordChange"
              :title="userComponentHeader"
              type="subHeader"
              icon-file="People"
              controls="passwordChangeAdminComponent"
            />
            <PasswordChangeAdminComponent
              v-if="updateEntityPermission && selectedUser.id !== currentUserId"
              id="passwordChangeAdminComponent"
              :username="selectedUser.userName"
              class="mdPwdChange"
              style="display:none"
              @user-updated="updateUserName($event)"
            />
            <SectionCollapsedHeader
              v-if="deleteEntityPermission && selectedUser.id !== currentUserId"
              id="deleteUserComponentSection"
              title="Delete User"
              type="subHeader"
              icon-file="Trashgreen"
              controls="deleteUserComponent"
            />
            <DeleteUser
              v-if="deleteEntityPermission && selectedUser.id !== currentUserId"
              id="deleteUserComponent"
              :userid="selectedUser.id"
              :username="selectedUsername"
              class="mdDeleteUser"
              style="display:none"
              @selectuser="setSelectedUser"
            />
            <SectionCollapsedHeader
              v-if="updateEntityPermission"
              id="sectionEnableAccount"
              type="subHeader"
              title="Enable/Disable Account"
              icon-file="Tick"
              controls="accountEnabledComponent"
            />
            <AccountEnabledComponent
              v-if="selectedUser && updateEntityPermission"
              id="accountEnabledComponent"
              :username="selectedUser.userName"
              :user="selectedUser"
              :selected-user="selectedUser"
              class="mdActivateAccount"
              style="display:none"
              @user-updated="updateUserName($event)"
            />
            <SectionCollapsedHeader
              v-if="viewCustomerPermission"
              id="sectionTopLevelSites"
              title="Manage Top Level Sites"
              type="subHeader"
              icon-file="People"
              controls="UserCustomerComponent"
            />
            <UserCustomerComponent
              v-if="viewCustomerPermission"
              id="UserCustomerComponent"
              :userid="selectedUser.id"
              :use-alt-colour="useAltColour"
              class="mdPwdChange"
              style="display:none"
              @user-updated="updateUserName($event)"
            />
            <SectionCollapsedHeader
              v-if="createEntityPermission"
              id="sectionSendWelcomeEmail"
              title="Send Welcome Email"
              type="subHeader"
              icon-file="Messages"
              controls="sendWelcomeEmail"
            />
            <SendWelcomeEmail
              v-if="createEntityPermission"
              :userid="selectedUser.id"
            />
            <!-- <SectionCollapsedHeader
              v-if="viewAssignedPermissionPermission"
              id="sectionUserPermissions"
              :title="userPermissionHeader"
              type="subHeader"
              icon-file="Lock"
              controls="permissionsComponent"
            />
            <PermissionsComponent
              id="permissionsComponent"
              :username="selectedUser.userName"
              :userid="selectedUser.id"
              :areaid="selectedUser.areaId"
              style="display:none"
            /> -->
          </div>
        </div>
      </div>
      <div
        v-else
        class="noResults"
      >
        Sorry, you do not have permission to manage users.
      </div>
    </div>
  </div>
</template>

<script>
import SectionCollapsedHeader from '../components/common/sectionCollapsedHeader'
// import PermissionsComponent from '../components/users/permissionsComponent'
import UpdateUser from '../components/users/updateUser'
import DeleteUser from '../components/users/deleteUser'
import AccountEnabledComponent from '../components/accountEnabled/accountEnabledComponent'
import PasswordChangeAdminComponent from '../components/passwordChange/passwordChangeAdminComponent'
import UserCustomerComponent from '../components/users/userCustomerComponent'
import SendWelcomeEmail from '../components/users/sendWelcomeEmail'
import ChangeUserEmail from '../components/users/changeUserEmail'
import tableHandler from '../mixins/tableHandler'
import { usersMixin } from '../mixins/users'
import { permissionsMixin } from '../mixins/permissions'
import { mapActions } from 'vuex'
import Spinner from '../components/common/spinner'
import { DataEventBus } from '../events/dataEvents'
import CreateUser from '../components/users/createUser'
import TextButton from '../components/common/textButton.vue'
import TriStateToggleSwitch from '../components/common/triStateToggleSwitch'
export default {
  name: 'UserManagement',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Maintel icon portal User Management' }
    ],
    title: 'icon portal - User Management'
  },
  components: {
    Spinner,
    SectionCollapsedHeader,
    AccountEnabledComponent,
    PasswordChangeAdminComponent,
    // PermissionsComponent,
    UpdateUser,
    CreateUser,
    DeleteUser,
    UserCustomerComponent,
    SendWelcomeEmail,
    ChangeUserEmail,
    TextButton,
    TriStateToggleSwitch
  },
  mixins: [
    usersMixin,
    permissionsMixin,
    tableHandler
  ],
  data () {
    return {
      loading: true,
      selectedUsername: '',
      userList: [],
      selectedUser: null,
      userComponentHeader: '',
      userPermissionHeader: '',
      updateUserComponent: '',
      selected: null,
      dataReturned: [],
      currentCustomerId: 0,
      showInternal: 2,
      doChange: false,
      loadingUsers: false
    }
  },
  computed: {
    isLoggedOff () {
      return this.$store.getters.loggedOff
    },
    loggedOnUsername () {
      return this.$store.getters.username
    },
    loggedOnUserid () {
      return this.$store.getters.userid
    },
    isLoggedOn () {
      return this.$store.getters.loggedOn
    },
    selectedCustomer () {
      return this.$store.getters.currentCustomer
    },
    reloadUsers () {
      return this.$store.getters.reloadUsers
    },
    viewEntityPermission () {
      return this.$store.getters.viewEntityPermission
    },
    createEntityPermission () {
      return this.$store.getters.createEntityPermission && this.$store.getters.viewRoleAreaPermission
    },
    updateEntityPermission () {
      return this.$store.getters.updateEntityPermission
    },
    deleteEntityPermission () {
      return this.$store.getters.deleteEntityPermission
    },
    viewAssignedPermissionPermission () {
      return this.$store.getters.viewAssignedPermissionPermission
    },
    viewCustomerPermission () {
      return this.$store.getters.viewCustomerPermission
    },
    newUser () {
      return require(`../assets/icons/new.svg`)
    },
    currentUserId () {
      return this.$store.getters.userid
    },
    currentUsers () {
      return this.$store.getters.users
    },
    internalUser () {
      return this.$store.getters.channelUsers.filter(u => u === this.$store.getters.userid).length > 0
    },
    useAltColour () {
      var styles = this.$store.getters.entityStyles
      var rtn = true
      if (styles.length > 0) {
        for (let i = 0; i < styles.length; i++) {
          if (styles[i].styleName === 'alt-colour') {
            if (styles[i].styleValue === 'false') {
              rtn = false
            }
          }
        }
      }
      return rtn
    }
  },
  watch: {
    reloadUsers (val) {
      if (val) {
        this.doChange = true
        this.getMyUsers()
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.users,
      (newValue) => {
        if (newValue) {
          this.getMyUsers()
        }
      }
    )
    DataEventBus.$on('usersSet', () => {
      this.doChange = true
      this.setSelectedUser()
      this.getMyUsers()
    })
    DataEventBus.$emit('still-active')
  },
  created () {
    this.checkPermission()
  },
  methods: {
    ...mapActions([
      'setPage',
      'setMainForm',
      'setReloadUsers',
      'setMainForm',
      'setSubForm',
      'setSubForm2'
    ]),
    closeForms () {
      this.setMainForm(false)
      this.setSubForm(false)
      this.setSubForm2(false)
    },
    openNewUser () {
      this.setMainForm(true)
    },
    selectUsername (selectedRow) {
      this.selectedUser = selectedRow
      this.selectedUsername = selectedRow.userName
      if (this.selected === selectedRow.id && this.selected !== null) {
        this.selected = '-1'
        this.selectedUser = null
      } else {
        this.selected = selectedRow.id
        this.setHeaderLabels()
      }
      DataEventBus.$emit('still-active')
    },
    getMyUsers () {
      this.loading = true
      if (!this.loadingUsers && this.viewEntityPermission && (this.selectedCustomer.id !== this.currentCustomerId || this.doChange)) {
        this.loadingUsers = true
        this.userList = []
        this.currentFilter = []
        this.dataReturned = []
        var userStringList = ''
        if (this.currentUsers.length > 0) {
          this.currentUsers.forEach(user => { userStringList += '|' + user.id })
          userStringList = userStringList.substring(1)
          this.getUserList(userStringList).then((result) => {
            this.dataReturned = result
            // remove the avatar so the search results aren't affected by this un-used field
            this.dataReturned.forEach((data) => delete data.avatar)
            // Remove maintel users if hide internal is selected
            if (this.showInternal === 1) {
              this.dataReturned = this.dataReturned.filter(user => user.emailAddress.toLowerCase().indexOf('@maintel.co.uk') === -1)
            }
            this.userList = this.dataReturned
            this.initTable()
          })
        } else {
          this.userList = this.dataReturned
          this.initTable()
        }
        this.currentCustomerId = this.selectedCustomer.id
        this.doChange = false
        this.loadingUsers = false
      }
      this.loading = false
    },
    getRowColour (id) {
      var rtn = 'LightGreen'
      if (!this.useAltColour) {
        rtn = 'SolidLightGreen'
      }
      if (this.selectSite === id) {
        rtn = 'itemSelected'
      }
      return rtn
    },
    initTable: function () {
      if (this.selectedUser) { this.resetSelected() }
      this.setHeaderLabels()
      this.initialiseTable('usersTable', this.userList,
        ['userName', 'firstName', 'lastName', 'emailAddress', 'locked', 'disabled'],
        ['User Name', 'First Name', 'Last Name', 'Email Address', 'Locked', 'Disabled'],
        ['', '', '', '', '', ''],
        null,
        ['20%', '15%', '15%', '30%', '95px', '95px'],
        null,
        true,
        'users.csv'
      )
    },
    updateUserName: async function (username) {
      var entity = await this.getUser(username)
      this.selectedUser = entity
    },
    setHeaderLabels () {
      if (this.selectedUser) {
        this.userComponentHeader = `Change Password` /* for ${this.selectedUser.userName}`; */
        this.userPermissionHeader = `Permissions at Site` /* for ${this.selectedUser.userName}`; */
        this.updateUserComponent = `User Details` /* for ${this.selectedUser.userName}`; */
      }
    },
    resetSelected () {
      for (let i = 0; i < this.userList.length; i++) {
        if (this.userList[i].id === this.selectedUser.id) {
          // this.selectedUser.userName = this.userList[i].userName;
          this.selectedUser = this.userList[i]
        }
      }
    },
    testSelectUser (event, selectedRow) {
      if (event.keyCode === 32) {
        this.selectUsername(selectedRow)
      }
    },
    checkPermission: async function () {
      if (this.viewEntityPermission) {
        this.getMyUsers()
        this.setMainForm(false)
        this.setPage('UserManagement')
      } else {
        this.setPage('Login')
      }
    },
    handleUserCreated: function () {
      this.usersGet().then(() => {
        this.$store.commit('showMessage', { content: 'User has been created successfully, an email has been sent to the associated account', color: 'blue', timeout: 3000 })
      })
    },
    toggleUsers (val) {
      this.showInternal = val.selectedState
      this.doChange = true
      this.getMyUsers()
    },
    setSelectedUser: function () {
      this.selectedUser = null
    }
  }
}
</script>

<style scoped>

  .appSectionHeader {
    display: grid;
    grid-template-columns: calc(100% - 160px) 160px;
    justify-content: end;
    align-items: start;
    margin-bottom: 10px;
    background: white;
  }
  .appSectionBody {
    margin-bottom: 40px;
  }
  .appSectionHeaderButtonHolder {
    min-height: 30px;
  }
  .divInternalUsers {
    display: inline-block;
  }
</style>
