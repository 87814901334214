import portalAPIGateway from '../../communications/interceptors/apiInterceptor'
export const iQuoteProfessionalServicesAPIMixin = {
  data () {
    return {
    }
  },
  methods: {
    iQuoteProfessionalServicesAPIMixin_getAxiosConfig () {
      var userid = this.$store.getters.userId
      return {
        timeout: 15000,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid,
          'CustomerId': this.$store.getters.currentCustomer.id
        }
      }
    },
    iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfiles: async function () {
      var data = []
      var axiosConfig = this.iQuoteProfessionalServicesAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/designpackprofessionalservices/getdesignpackpsroleprofiles', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfiles error: ' + error)
      }
      return data
    },
    iQuoteProfessionalServicesAPIMixin_getDesignPackPSSchedules: async function () {
      var data = []
      var axiosConfig = this.iQuoteProfessionalServicesAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/designpackprofessionalservices/getdesignpackpsschedules', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteProfessionalServicesAPIMixin_getDesignPackPSSchedules error: ' + error)
      }
      return data
    },
    iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfileSchedules: async function () {
      var data = []
      var axiosConfig = this.iQuoteProfessionalServicesAPIMixin_getAxiosConfig()
      try {
        await portalAPIGateway.get('iquotearray/designpackprofessionalservices/getdesignpackpsroleprofileschedules', axiosConfig).then((response) => {
          data = response.data
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log('iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfileSchedules error: ' + error)
      }
      return data
    }
  }
}
