import { mapActions } from 'vuex'
import { iQuoteStaticDataAPIMixin } from './iQuoteStaticDataAPIMixin'
import { iQuoteProfessionalServicesAPIMixin } from './iQuoteProfessionalServicesAPIMixin'

export const iQuoteInitialiseMixin = {
  mixins: [iQuoteStaticDataAPIMixin, iQuoteProfessionalServicesAPIMixin],
  data () {
    return {
      termLengthsLoaded: false,
      cxNowPlatformSizesLoaded: false,
      resiliencesLoaded: false,
      virtualMachinesLoaded: false,
      bladeServersLoaded: false,
      licensesLoaded: false,
      unitCostsLoaded: false,
      applicationLicenseTypesLoaded: false,
      serviceLevelsLoaded: false,
      serviceListLoaded: false,
      wfoOptionsLoaded: false,
      codecsLoaded: false,
      oohPortingsLoaded: false,
      sipAccessLoaded: false,
      sipAccessTypesLoaded: false,
      userProfileLicensesLoaded: false,
      userProfileCategoriesLoaded: false,
      packageVirtualMachinesLoaded: false,
      serviceCategoriesLoaded: false,
      expansionVmCategoriesLoaded: false,
      expansionVmSubCategoriesLoaded: false,
      virtualMachineCategoryLinksLoaded: false,
      designPackTableSectionsLoaded: false,
      technologyUserProfilesLoaded: false,
      technologyHardwareLoaded: false,
      cxNowAppsLoaded: false,
      cxNowConnectorsLoaded: false,
      salesforceCoeCodesLoaded: false,
      avayaAXPSocialMediaPackagesLoaded: false,
      professionalServicesLookupsLoaded: false,
      ucAnalyticsQuoteTypesLoaded: false
    }
  },
  computed: {
    iQuoteInitialise_isLoaded () {
      var allAreLoaded = this.termLengthsLoaded &&
        this.resiliencesLoaded &&
        this.virtualMachinesLoaded &&
        this.bladeServersLoaded &&
        this.licensesLoaded &&
        this.unitCostsLoaded &&
        this.applicationLicenseTypesLoaded &&
        this.serviceLevelsLoaded &&
        this.serviceListLoaded &&
        this.wfoOptionsLoaded &&
        this.codecsLoaded &&
        this.oohPortingsLoaded &&
        this.sipAccessLoaded &&
        this.sipAccessTypesLoaded &&
        this.userProfileLicensesLoaded &&
        this.userProfileCategoriesLoaded &&
        this.cxNowPlatformSizesLoaded &&
        this.packageVirtualMachinesLoaded &&
        this.serviceCategoriesLoaded &&
        this.expansionVmCategoriesLoaded &&
        this.expansionVmSubCategoriesLoaded &&
        this.virtualMachineCategoryLinksLoaded &&
        this.designPackTableSectionsLoaded &&
        this.technologyHardwareLoaded &&
        this.technologyUserProfilesLoaded &&
        this.cxNowAppsLoaded &&
        this.cxNowConnectorsLoaded &&
        this.salesforceCoeCodesLoaded &&
        this.avayaAXPSocialMediaPackagesLoaded &&
        this.professionalServicesLookupsLoaded &&
        this.ucAnalyticsQuoteTypesLoaded

      return allAreLoaded
    }
  },
  async mounted () {
  },
  methods: {
    ...mapActions([
      'setTermLengths',
      'setResiliences',
      'setApplicationLicenseTypes',
      'setServiceLevels',
      'setServiceList',
      'setWFOOptions',
      'setCodecs',
      'setOOHPortings',
      'setSipAccessTypes',
      'setSipAccess',
      'setVirtualMachines',
      'setBladeServers',
      'setLicenses',
      'setUnitCosts',
      'setUserProfileLicenses',
      'setUserProfileCategories',
      'setCXNowPlatformSizes',
      'setPackageVirtualMachines',
      'setServiceCategories',
      'setExpansionVmCategories',
      'setExpansionVmSubCategories',
      'setVirtualMachineExpansionCategoryLinks',
      'setDesignPackTableSections',
      'setTechnologyUserProfiles',
      'setTechnologyHardware',
      'setCxNowApps',
      'setCxNowConnectors',
      'setSalesforceCoeCodes',
      'setAvayaAXPSocialMediaPackages',
      'setProfessionalServicesLookups',      
      'setUCAnalyticsQuoteTypes'
    ]),
    async iQuoteInitialise_loadStaticData () {
      await this.iQuoteInitialise_getiQuoteTermLengths()
      await this.iQuoteInitialise_getiQuoteResiliences()
      await this.iQuoteInitialise_getiQuoteApplicationLicenseTypes()
      await this.iQuoteInitialise_getiQuoteServiceLevels()
      await this.iQuoteInitialise_getiQuoteServiceList()
      await this.iQuoteInitialise_getiQuoteWFOOptions()
      await this.iQuoteInitialise_getiQuoteCodecs()
      await this.iQuoteInitialise_getiQuoteOOHPortings()
      await this.iQuoteInitialise_getiQuoteVirtualMachines()
      await this.iQuoteInitialise_getiQuoteBladeServers()
      await this.iQuoteInitialise_getiQuoteLicenses()
      await this.iQuoteInitialise_getiQuoteUnitCosts()
      await this.iQuoteInitialise_getiQuoteUserProfileLicenses()
      await this.iQuoteInitialise_getiQuoteUserProfileCategories()
      await this.iQuoteInitialise_getiQuoteCXNowPlatformSizes()
      await this.iQuoteInitialise_getiQuotePackageVirtualMachines()
      await this.iQuoteInitialise_getiQuoteServiceCategories()
      await this.iQuoteInitialise_getiQuoteSipAccess()
      await this.iQuoteInitialise_getiQuoteSipAccessTypes()
      await this.iQuoteInitialise_getiQuoteExpansionVmCategories()
      await this.iQuoteInitialise_getiQuoteExpansionVmSubCategories()
      await this.iQuoteInitialise_getiQuoteVirtualMachineExpansionCategoryLinks()
      await this.iQuoteInitialise_getiQuoteDesignPackTableSections()
      await this.iQuoteInitialise_getiQuoteTechnologyHardware()
      await this.iQuoteInitialise_getiQuoteTechnologyUserProfiles()
      await this.iQuoteInitialise_getiQuoteCxNowApps()
      await this.iQuoteInitialise_getiQuoteCxNowConnectors()
      await this.iQuoteInitialise_getiQuoteSalesforceCoeCodes()
      await this.iQuoteInitialise_getiQuoteAvayaAXPSocialMediaPackages()
      await this.iQuoteInitialise_getiQuoteProfessionalServicesLookups()
      await this.iQuoteInitialise_getiQuoteUCAnalyticsQuoteTypes()
    },
    iQuoteInitialise_getiQuoteTermLengths: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getTermLengths()
      this.setTermLengths(returnedData)
      this.termLengthsLoaded = true
    },
    iQuoteInitialise_getiQuoteVirtualMachines: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getVirtualMachines()
      this.setVirtualMachines(returnedData)
      // console.log('vms')
      // console.log(returnedData)
      this.virtualMachinesLoaded = true
    },
    iQuoteInitialise_getiQuoteVirtualMachineExpansionCategoryLinks: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getVirtualMachineExpansionCategoryLinks()
      this.setVirtualMachineExpansionCategoryLinks(returnedData)
      this.virtualMachineCategoryLinksLoaded = true
    },
    iQuoteInitialise_getiQuoteBladeServers: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getBladeServers()
      this.setBladeServers(returnedData)
      this.bladeServersLoaded = true
    },
    iQuoteInitialise_getiQuoteLicenses: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getLicenses()
      this.setLicenses(returnedData)
      this.licensesLoaded = true
    },
    iQuoteInitialise_getiQuoteUnitCosts: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getUnitCosts()
      this.setUnitCosts(returnedData)
      this.unitCostsLoaded = true
    },
    iQuoteInitialise_getiQuoteResiliences: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getResiliences()
      this.setResiliences(returnedData)
      this.resiliencesLoaded = true
    },
    iQuoteInitialise_getiQuoteApplicationLicenseTypes: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getApplicationLicenseTypes()
      this.setApplicationLicenseTypes(returnedData)
      this.applicationLicenseTypesLoaded = true
    },
    iQuoteInitialise_getiQuoteServiceLevels: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getServiceLevels()
      this.setServiceLevels(returnedData)
      this.serviceLevelsLoaded = true
    },
    iQuoteInitialise_getiQuoteServiceList: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getServices()
      this.setServiceList(returnedData)
      this.serviceListLoaded = true
    },
    iQuoteInitialise_getiQuoteServiceCategories: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getServiceCategories()
      this.setServiceCategories(returnedData)
      this.serviceCategoriesLoaded = true
    },
    iQuoteInitialise_getiQuoteWFOOptions: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getWFOOptions()
      this.setWFOOptions(returnedData)
      this.wfoOptionsLoaded = true
    },
    iQuoteInitialise_getiQuoteCodecs: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getSIPCodecs()
      this.setCodecs(returnedData)
      this.codecsLoaded = true
    },
    iQuoteInitialise_getiQuoteExpansionVmCategories: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getExpansionVmCategories()
      this.setExpansionVmCategories(returnedData)
      this.expansionVmCategoriesLoaded = true
    },
    iQuoteInitialise_getiQuoteExpansionVmSubCategories: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getExpansionVmSubCategories()
      this.setExpansionVmSubCategories(returnedData)
      this.expansionVmSubCategoriesLoaded = true
    },
    iQuoteInitialise_getiQuoteOOHPortings: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getOOHPortings()
      this.setOOHPortings(returnedData)
      this.oohPortingsLoaded = true
    },
    iQuoteInitialise_getiQuoteSipAccess: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getSipAccess()
      this.setSipAccess(returnedData)
      this.sipAccessLoaded = true
    },
    iQuoteInitialise_getiQuoteSipAccessTypes: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getSipAccessTypes()
      this.setSipAccessTypes(returnedData)
      this.sipAccessTypesLoaded = true
    },
    iQuoteInitialise_getiQuoteUserProfileLicenses: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getUserProfileLicenses()
      this.setUserProfileLicenses(returnedData)
      this.userProfileLicensesLoaded = true
    },
    iQuoteInitialise_getiQuoteUserProfileCategories: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getUserProfileCategories()
      this.setUserProfileCategories(returnedData)
      this.userProfileCategoriesLoaded = true
    },
    iQuoteInitialise_getiQuoteCXNowPlatformSizes: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getCXNowPlatformSizes()
      this.setCXNowPlatformSizes(returnedData)
      this.cxNowPlatformSizesLoaded = true
    },
    iQuoteInitialise_getiQuotePackageVirtualMachines: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getPackageVirtualMachines()
      this.setPackageVirtualMachines(returnedData)
      this.packageVirtualMachinesLoaded = true
    },
    iQuoteInitialise_getiQuoteDesignPackTableSections: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getDesignPackTableSections()
      this.setDesignPackTableSections(returnedData)
      this.designPackTableSectionsLoaded = true
    },
    iQuoteInitialise_getiQuoteTechnologyUserProfiles: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getTechnologyUserProfiles()
      this.setTechnologyUserProfiles(returnedData)
      this.technologyUserProfilesLoaded = true
    },
    iQuoteInitialise_getiQuoteTechnologyHardware: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getTechnologyHardware()
      this.setTechnologyHardware(returnedData)
      this.technologyHardwareLoaded = true
    },
    iQuoteInitialise_getiQuoteCxNowApps: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getCxNowApps()
      this.setCxNowApps(returnedData)
      this.cxNowAppsLoaded = true
    },
    iQuoteInitialise_getiQuoteCxNowConnectors: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getCxNowConnectors()
      this.setCxNowConnectors(returnedData)
      this.cxNowConnectorsLoaded = true
    },
    iQuoteInitialise_getiQuoteSalesforceCoeCodes: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getSalesforceCoeCodes()
      this.setSalesforceCoeCodes(returnedData)
      this.salesforceCoeCodesLoaded = true
    },
    iQuoteInitialise_getiQuoteAvayaAXPSocialMediaPackages: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getAvayaAXPSocialMediaPackages()
      this.setAvayaAXPSocialMediaPackages(returnedData)
      this.avayaAXPSocialMediaPackagesLoaded = true
    },
    iQuoteInitialise_getiQuoteProfessionalServicesLookups: async function () {
      const psRoleProfiles = await this.iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfiles()
      const psSchedules = await this.iQuoteProfessionalServicesAPIMixin_getDesignPackPSSchedules()
      const psRoleProfileSchedules = await this.iQuoteProfessionalServicesAPIMixin_getDesignPackPSRoleProfileSchedules()
      const professionalServicesLookups = {
        designPackPSRoleProfiles: psRoleProfiles,
        designPackPSSchedules: psSchedules,
        designPackPSRoleProfileSchedules: psRoleProfileSchedules
      }
      this.setProfessionalServicesLookups(professionalServicesLookups)
      this.professionalServicesLookupsLoaded = true
    },
    iQuoteInitialise_getiQuoteUCAnalyticsQuoteTypes: async function () {
      var returnedData = await this.iQuoteStaticDataAPIMixin_getUCAnalyticsQuoteTypes()
      this.setUCAnalyticsQuoteTypes(returnedData)
      this.ucAnalyticsQuoteTypesLoaded = true
    }
  }
}
